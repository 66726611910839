// stores/authStore.ts
import { defineStore } from 'pinia';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: '',
    userId: '',
    companyId: '',
    partnerId: '',
    partnerName: '',
    is_trial_mode: true,
    accessIds: [] as Array<{ menu: string }>
  }),

  actions: {
    setToken(token: string) {
      this.token = token;
      this.persistToken();
    },
    clearToken() {
      this.token = '';
      this.persistToken();
    },
    persistToken() {
      cookies.set('authToken', this.token, { path: '/' });
    },
    loadToken() {
      return new Promise<void>((resolve) => {
        const token = cookies.get('authToken');
        if (token) {
          this.token = token;
        }
        resolve();
      });
    },
    setUser(userData: {
      token: string;
      userId: string;
      companyId: string;
      partnerId: string;
      partnerName: string;
      is_trial_mode: boolean;
      accessIds: Array<{ menu: string }>
    }) {
      this.token = userData.token;
      this.userId = userData.userId;
      this.companyId = userData.companyId;
      this.partnerId = userData.partnerId;
      this.partnerName = userData.partnerName;
      this.accessIds = userData.accessIds;
      this.is_trial_mode = userData.is_trial_mode;
      this.persistUser();
    },
    clearUser() {
      this.token = '';
      this.userId = '';
      this.companyId = '';
      this.partnerId = '';
      this.partnerName = '';
      this.accessIds = [];
      this.is_trial_mode = true;
      this.persistUser();
    },
    persistUser() {
      const userData = {
        token: this.token,
        userId: this.userId,
        companyId: this.companyId,
        partnerId: this.partnerId,
        partnerName: this.partnerName,
        accessIds: this.accessIds,
        is_trial_mode: this.is_trial_mode,
      };
      const userDataString = JSON.stringify(userData);
    //   console.log('Storing user data in cookies:', userDataString);
      cookies.set('authStore', userDataString, { path: '/' });
    },
    loadUser() {
      return new Promise<void>((resolve) => {
        const userData = cookies.get('authStore');
        if (userData) {
          try {

            // Check if the data is already an object or needs parsing
            const parsedData = typeof userData === 'string' ? JSON.parse(userData) : userData;
            this.token = parsedData.token;
            this.userId = parsedData.userId;
            this.companyId = parsedData.companyId;
            this.partnerId = parsedData.partnerId;
            this.partnerName = parsedData.partnerName;
            this.accessIds = parsedData.accessIds;
            this.is_trial_mode = parsedData.is_trial_mode;

          } catch (error) {
            console.error('Failed to parse user data from cookies:', error);
            this.clearUser();
          }
        }
        resolve();
      });
    },
    signOut() {
      this.clearUser();
      cookies.remove('authStore', { path: '/' });
    }
  },

  getters: {
    isAuthenticated: (state) => !!state.token,
    getUser: (state) => ({
      token: state.token,
      userId: state.userId,
      companyId: state.companyId,
      partnerId: state.partnerId,
      partnerName: state.partnerName,
      accessIds: state.accessIds,
      is_trial_mode: state.is_trial_mode,
    })
  }
});
