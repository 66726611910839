import { default as contractT7mSJX2QsJMeta } from "/app/pages/admin/apps/bussiness/contract.vue?macro=true";
import { default as indexk1G1sQDyHOMeta } from "/app/pages/admin/apps/bussiness/index.vue?macro=true";
import { default as calendar1RLu1JEQl9Meta } from "/app/pages/admin/apps/calendar.vue?macro=true";
import { default as chatyIMVLlPeCCMeta } from "/app/pages/admin/apps/chat.vue?macro=true";
import { default as contacts1Ak3gzjLe1Meta } from "/app/pages/admin/apps/contacts.vue?macro=true";
import { default as activitypdMT2Vs7jGMeta } from "/app/pages/admin/apps/crm/activity.vue?macro=true";
import { default as detail_leadss07GFgjid5Meta } from "/app/pages/admin/apps/crm/detail_leads.vue?macro=true";
import { default as indexVhE32NzxbYMeta } from "/app/pages/admin/apps/crm/index.vue?macro=true";
import { default as index5i214nhpmiMeta } from "/app/pages/admin/apps/financials/index.vue?macro=true";
import { default as prepaidbcqT8KzYcLMeta } from "/app/pages/admin/apps/financials/prepaid.vue?macro=true";
import { default as addVcbfOQsKgtMeta } from "/app/pages/admin/apps/invoice/add.vue?macro=true";
import { default as edit8hirfV1m2sMeta } from "/app/pages/admin/apps/invoice/edit.vue?macro=true";
import { default as listXRhG5XeWgsMeta } from "/app/pages/admin/apps/invoice/list.vue?macro=true";
import { default as previewfd9uyFoFYLMeta } from "/app/pages/admin/apps/invoice/preview.vue?macro=true";
import { default as addJCX2YAgyoEMeta } from "/app/pages/admin/apps/listing/add.vue?macro=true";
import { default as indexrHPkv19k52Meta } from "/app/pages/admin/apps/listing/index.vue?macro=true";
import { default as mailboxCbBiG8SoKdMeta } from "/app/pages/admin/apps/mailbox.vue?macro=true";
import { default as marketing0t9B7qW7GLMeta } from "/app/pages/admin/apps/marketing.vue?macro=true";
import { default as notesuJce14B4jdMeta } from "/app/pages/admin/apps/notes.vue?macro=true";
import { default as opportunitiesgzfaHtfqiOMeta } from "/app/pages/admin/apps/opportunities.vue?macro=true";
import { default as scrumboardlI93o3ReP4Meta } from "/app/pages/admin/apps/scrumboard.vue?macro=true";
import { default as smartactionpev8xOLj8qMeta } from "/app/pages/admin/apps/smartaction.vue?macro=true";
import { default as todolistE1QUUnGwOYMeta } from "/app/pages/admin/apps/todolist.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as profile0GQR50wn91Meta } from "/app/pages/admin/users/profile.vue?macro=true";
import { default as user_45account_45settingsF0Fn7eYxJgMeta } from "/app/pages/admin/users/user-account-settings.vue?macro=true";
import { default as analyticsawZ4iWXl3HMeta } from "/app/pages/analytics.vue?macro=true";
import { default as boxed_45lockscreen9PuutlCpOVMeta } from "/app/pages/auth/boxed-lockscreen.vue?macro=true";
import { default as boxed_45password_45reset47WA1dDqs6Meta } from "/app/pages/auth/boxed-password-reset.vue?macro=true";
import { default as boxed_45signinPQdz4KevkGMeta } from "/app/pages/auth/boxed-signin.vue?macro=true";
import { default as boxed_45signup6O9TBPg4tGMeta } from "/app/pages/auth/boxed-signup.vue?macro=true";
import { default as cover_45lockscreenYk2MgkwpFmMeta } from "/app/pages/auth/cover-lockscreen.vue?macro=true";
import { default as cover_45loginuZyWJQcrBoMeta } from "/app/pages/auth/cover-login.vue?macro=true";
import { default as cover_45password_45reset0Dp209zw2BMeta } from "/app/pages/auth/cover-password-reset.vue?macro=true";
import { default as cover_45registervmnxlWtp1cMeta } from "/app/pages/auth/cover-register.vue?macro=true";
import { default as try_45demouZyExdwi4bMeta } from "/app/pages/auth/try-demo.vue?macro=true";
import { default as indexZylsoS0OD7Meta } from "/app/pages/b2c/index.vue?macro=true";
import { default as marketingnqQ7kgu8hvMeta } from "/app/pages/b2c/marketing.vue?macro=true";
import { default as chartsmXog1AvpIqMeta } from "/app/pages/charts.vue?macro=true";
import { default as accordionsUwvwTzg8igMeta } from "/app/pages/components/accordions.vue?macro=true";
import { default as cardsPjbJoa7ViHMeta } from "/app/pages/components/cards.vue?macro=true";
import { default as carouselaGFIr3YbFiMeta } from "/app/pages/components/carousel.vue?macro=true";
import { default as countdownlM1qZBRClqMeta } from "/app/pages/components/countdown.vue?macro=true";
import { default as counterhV3CROPmoPMeta } from "/app/pages/components/counter.vue?macro=true";
import { default as lightboxzYXPqqPhLRMeta } from "/app/pages/components/lightbox.vue?macro=true";
import { default as list_45groupzPlFcWhgP8Meta } from "/app/pages/components/list-group.vue?macro=true";
import { default as media_45objectLlNVOsVPpEMeta } from "/app/pages/components/media-object.vue?macro=true";
import { default as modalsvcZKjEcmSIMeta } from "/app/pages/components/modals.vue?macro=true";
import { default as notificationsmlJEGUOXcIMeta } from "/app/pages/components/notifications.vue?macro=true";
import { default as pricing_45tablew9AhkmytE4Meta } from "/app/pages/components/pricing-table.vue?macro=true";
import { default as sweetalert0d0XYlgpbCMeta } from "/app/pages/components/sweetalert.vue?macro=true";
import { default as tabsxnPxR4FkY3Meta } from "/app/pages/components/tabs.vue?macro=true";
import { default as timeline6adsDXNVPUMeta } from "/app/pages/components/timeline.vue?macro=true";
import { default as crypto3wfXLz1OUkMeta } from "/app/pages/crypto.vue?macro=true";
import { default as advancedn4tKgGV5IhMeta } from "/app/pages/datatables/advanced.vue?macro=true";
import { default as alt_45paginationChZ9tlCMFJMeta } from "/app/pages/datatables/alt-pagination.vue?macro=true";
import { default as basicAcR9PDUtBPMeta } from "/app/pages/datatables/basic.vue?macro=true";
import { default as checkboxIMYFCQ1X0QMeta } from "/app/pages/datatables/checkbox.vue?macro=true";
import { default as clone_45headerOoQyiYCewtMeta } from "/app/pages/datatables/clone-header.vue?macro=true";
import { default as column_45chooserM4a7xaUmrFMeta } from "/app/pages/datatables/column-chooser.vue?macro=true";
import { default as columns_45filter3pnQpBq1PNMeta } from "/app/pages/datatables/columns-filter.vue?macro=true";
import { default as exporteznYh68XblMeta } from "/app/pages/datatables/export.vue?macro=true";
import { default as multi_45column47lVAQAb3oMeta } from "/app/pages/datatables/multi-column.vue?macro=true";
import { default as multiple_45tablesqL0WjDoP4bMeta } from "/app/pages/datatables/multiple-tables.vue?macro=true";
import { default as order_45sortingEPRDOqKsgwMeta } from "/app/pages/datatables/order-sorting.vue?macro=true";
import { default as range_45searchxRIL6qkmVBMeta } from "/app/pages/datatables/range-search.vue?macro=true";
import { default as skinfZD3H3UBGJMeta } from "/app/pages/datatables/skin.vue?macro=true";
import { default as sticky_45headerNx7TRt8f24Meta } from "/app/pages/datatables/sticky-header.vue?macro=true";
import { default as dragndropQCs8cVnwWuMeta } from "/app/pages/dragndrop.vue?macro=true";
import { default as alerts7wwYLn17IUMeta } from "/app/pages/elements/alerts.vue?macro=true";
import { default as avatarA2Pd91LZRYMeta } from "/app/pages/elements/avatar.vue?macro=true";
import { default as badgesLzwDfXJnRzMeta } from "/app/pages/elements/badges.vue?macro=true";
import { default as breadcrumbs0MWvM7qnSYMeta } from "/app/pages/elements/breadcrumbs.vue?macro=true";
import { default as buttons_45groupIyDmfkCUs8Meta } from "/app/pages/elements/buttons-group.vue?macro=true";
import { default as buttonsKbjhiBShY1Meta } from "/app/pages/elements/buttons.vue?macro=true";
import { default as color_45libraryxNIfy6dCEcMeta } from "/app/pages/elements/color-library.vue?macro=true";
import { default as dropdownfgz6OL21RPMeta } from "/app/pages/elements/dropdown.vue?macro=true";
import { default as infobox6LfI5v5AjpMeta } from "/app/pages/elements/infobox.vue?macro=true";
import { default as jumbotronbQAftCp2hwMeta } from "/app/pages/elements/jumbotron.vue?macro=true";
import { default as loaderXrkieGQGJBMeta } from "/app/pages/elements/loader.vue?macro=true";
import { default as paginationXlxxYI4iKNMeta } from "/app/pages/elements/pagination.vue?macro=true";
import { default as popoversgWqJUdpnWoMeta } from "/app/pages/elements/popovers.vue?macro=true";
import { default as progress_45barAR6RX16OgyMeta } from "/app/pages/elements/progress-bar.vue?macro=true";
import { default as searchXT3z5qS2y1Meta } from "/app/pages/elements/search.vue?macro=true";
import { default as tooltipsfDf31TBwa5Meta } from "/app/pages/elements/tooltips.vue?macro=true";
import { default as treeviewRw0hin8tbUMeta } from "/app/pages/elements/treeview.vue?macro=true";
import { default as typographyeHAr3Wa5DEMeta } from "/app/pages/elements/typography.vue?macro=true";
import { default as financeMjIGPbjS9hMeta } from "/app/pages/finance.vue?macro=true";
import { default as font_45icons30g3Sron1XMeta } from "/app/pages/font-icons.vue?macro=true";
import { default as basic4d0ZhFQRAaMeta } from "/app/pages/forms/basic.vue?macro=true";
import { default as checkbox_45radiog0clgjf09hMeta } from "/app/pages/forms/checkbox-radio.vue?macro=true";
import { default as clipboardT5kxFLN3FCMeta } from "/app/pages/forms/clipboard.vue?macro=true";
import { default as date_45pickerxBpRZUt9KbMeta } from "/app/pages/forms/date-picker.vue?macro=true";
import { default as file_45uploadqdBcW6XYnhMeta } from "/app/pages/forms/file-upload.vue?macro=true";
import { default as input_45groupS5PaG385fyMeta } from "/app/pages/forms/input-group.vue?macro=true";
import { default as input_45maskJ05OMPC6ZtMeta } from "/app/pages/forms/input-mask.vue?macro=true";
import { default as layoutshU8mEbz15ZMeta } from "/app/pages/forms/layouts.vue?macro=true";
import { default as markdown_45editorX8EdcpWp7yMeta } from "/app/pages/forms/markdown-editor.vue?macro=true";
import { default as quill_45editorMEMRSEcMdTMeta } from "/app/pages/forms/quill-editor.vue?macro=true";
import { default as select2Kddnwvxd1NMeta } from "/app/pages/forms/select2.vue?macro=true";
import { default as switchesQX9jLi1wm0Meta } from "/app/pages/forms/switches.vue?macro=true";
import { default as touchspin20lQo5txyJMeta } from "/app/pages/forms/touchspin.vue?macro=true";
import { default as validationoK9hoJ34BkMeta } from "/app/pages/forms/validation.vue?macro=true";
import { default as wizardskeh2RYDhnpMeta } from "/app/pages/forms/wizards.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as mailboxNUDjlv6tulMeta } from "/app/pages/mailbox.vue?macro=true";
import { default as network3nefSS6RrdMeta } from "/app/pages/network.vue?macro=true";
import { default as orderBjfxujOe1OMeta } from "/app/pages/order.vue?macro=true";
import { default as coming_45soon_45boxedIiRVY5RmnYMeta } from "/app/pages/pages/coming-soon-boxed.vue?macro=true";
import { default as coming_45soon_45cover9En00x5dcNMeta } from "/app/pages/pages/coming-soon-cover.vue?macro=true";
import { default as contact_45us_45boxedCNp7hQWXH5Meta } from "/app/pages/pages/contact-us-boxed.vue?macro=true";
import { default as contact_45us_45coverDqlH83GANbMeta } from "/app/pages/pages/contact-us-cover.vue?macro=true";
import { default as error404hTN0doY6zqMeta } from "/app/pages/pages/error404.vue?macro=true";
import { default as error500OQ7pwxlt92Meta } from "/app/pages/pages/error500.vue?macro=true";
import { default as error503mOESQwwiUHMeta } from "/app/pages/pages/error503.vue?macro=true";
import { default as faq9zkrynBMU3Meta } from "/app/pages/pages/faq.vue?macro=true";
import { default as knowledge_45baseZEbA2pHmpEMeta } from "/app/pages/pages/knowledge-base.vue?macro=true";
import { default as maintenenceZfksFlNFGfMeta } from "/app/pages/pages/maintenence.vue?macro=true";
import { default as success0bZk2aWKHZMeta } from "/app/pages/success.vue?macro=true";
import { default as tablesObwGmyK6sPMeta } from "/app/pages/tables.vue?macro=true";
import { default as trial_45modevh6rBg3TbfMeta } from "/app/pages/trial-mode.vue?macro=true";
import { default as widgets3jvG0ysNM4Meta } from "/app/pages/widgets.vue?macro=true";
export default [
  {
    name: contractT7mSJX2QsJMeta?.name ?? "admin-apps-bussiness-contract",
    path: contractT7mSJX2QsJMeta?.path ?? "/admin/apps/bussiness/contract",
    meta: contractT7mSJX2QsJMeta || {},
    alias: contractT7mSJX2QsJMeta?.alias || [],
    redirect: contractT7mSJX2QsJMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/bussiness/contract.vue").then(m => m.default || m)
  },
  {
    name: indexk1G1sQDyHOMeta?.name ?? "admin-apps-bussiness",
    path: indexk1G1sQDyHOMeta?.path ?? "/admin/apps/bussiness",
    meta: indexk1G1sQDyHOMeta || {},
    alias: indexk1G1sQDyHOMeta?.alias || [],
    redirect: indexk1G1sQDyHOMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/bussiness/index.vue").then(m => m.default || m)
  },
  {
    name: calendar1RLu1JEQl9Meta?.name ?? "admin-apps-calendar",
    path: calendar1RLu1JEQl9Meta?.path ?? "/admin/apps/calendar",
    meta: calendar1RLu1JEQl9Meta || {},
    alias: calendar1RLu1JEQl9Meta?.alias || [],
    redirect: calendar1RLu1JEQl9Meta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/calendar.vue").then(m => m.default || m)
  },
  {
    name: chatyIMVLlPeCCMeta?.name ?? "admin-apps-chat",
    path: chatyIMVLlPeCCMeta?.path ?? "/admin/apps/chat",
    meta: chatyIMVLlPeCCMeta || {},
    alias: chatyIMVLlPeCCMeta?.alias || [],
    redirect: chatyIMVLlPeCCMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/chat.vue").then(m => m.default || m)
  },
  {
    name: contacts1Ak3gzjLe1Meta?.name ?? "admin-apps-contacts",
    path: contacts1Ak3gzjLe1Meta?.path ?? "/admin/apps/contacts",
    meta: contacts1Ak3gzjLe1Meta || {},
    alias: contacts1Ak3gzjLe1Meta?.alias || [],
    redirect: contacts1Ak3gzjLe1Meta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/contacts.vue").then(m => m.default || m)
  },
  {
    name: activitypdMT2Vs7jGMeta?.name ?? "admin-apps-crm-activity",
    path: activitypdMT2Vs7jGMeta?.path ?? "/admin/apps/crm/activity",
    meta: activitypdMT2Vs7jGMeta || {},
    alias: activitypdMT2Vs7jGMeta?.alias || [],
    redirect: activitypdMT2Vs7jGMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/crm/activity.vue").then(m => m.default || m)
  },
  {
    name: detail_leadss07GFgjid5Meta?.name ?? "admin-apps-crm-detail_leads",
    path: detail_leadss07GFgjid5Meta?.path ?? "/admin/apps/crm/detail_leads",
    meta: detail_leadss07GFgjid5Meta || {},
    alias: detail_leadss07GFgjid5Meta?.alias || [],
    redirect: detail_leadss07GFgjid5Meta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/crm/detail_leads.vue").then(m => m.default || m)
  },
  {
    name: indexVhE32NzxbYMeta?.name ?? "admin-apps-crm",
    path: indexVhE32NzxbYMeta?.path ?? "/admin/apps/crm",
    meta: indexVhE32NzxbYMeta || {},
    alias: indexVhE32NzxbYMeta?.alias || [],
    redirect: indexVhE32NzxbYMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/crm/index.vue").then(m => m.default || m)
  },
  {
    name: index5i214nhpmiMeta?.name ?? "admin-apps-financials",
    path: index5i214nhpmiMeta?.path ?? "/admin/apps/financials",
    meta: index5i214nhpmiMeta || {},
    alias: index5i214nhpmiMeta?.alias || [],
    redirect: index5i214nhpmiMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/financials/index.vue").then(m => m.default || m)
  },
  {
    name: prepaidbcqT8KzYcLMeta?.name ?? "admin-apps-financials-prepaid",
    path: prepaidbcqT8KzYcLMeta?.path ?? "/admin/apps/financials/prepaid",
    meta: prepaidbcqT8KzYcLMeta || {},
    alias: prepaidbcqT8KzYcLMeta?.alias || [],
    redirect: prepaidbcqT8KzYcLMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/financials/prepaid.vue").then(m => m.default || m)
  },
  {
    name: addVcbfOQsKgtMeta?.name ?? "admin-apps-invoice-add",
    path: addVcbfOQsKgtMeta?.path ?? "/admin/apps/invoice/add",
    meta: addVcbfOQsKgtMeta || {},
    alias: addVcbfOQsKgtMeta?.alias || [],
    redirect: addVcbfOQsKgtMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/invoice/add.vue").then(m => m.default || m)
  },
  {
    name: edit8hirfV1m2sMeta?.name ?? "admin-apps-invoice-edit",
    path: edit8hirfV1m2sMeta?.path ?? "/admin/apps/invoice/edit",
    meta: edit8hirfV1m2sMeta || {},
    alias: edit8hirfV1m2sMeta?.alias || [],
    redirect: edit8hirfV1m2sMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/invoice/edit.vue").then(m => m.default || m)
  },
  {
    name: listXRhG5XeWgsMeta?.name ?? "admin-apps-invoice-list",
    path: listXRhG5XeWgsMeta?.path ?? "/admin/apps/invoice/list",
    meta: listXRhG5XeWgsMeta || {},
    alias: listXRhG5XeWgsMeta?.alias || [],
    redirect: listXRhG5XeWgsMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/invoice/list.vue").then(m => m.default || m)
  },
  {
    name: previewfd9uyFoFYLMeta?.name ?? "admin-apps-invoice-preview",
    path: previewfd9uyFoFYLMeta?.path ?? "/admin/apps/invoice/preview",
    meta: previewfd9uyFoFYLMeta || {},
    alias: previewfd9uyFoFYLMeta?.alias || [],
    redirect: previewfd9uyFoFYLMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/invoice/preview.vue").then(m => m.default || m)
  },
  {
    name: addJCX2YAgyoEMeta?.name ?? "admin-apps-listing-add",
    path: addJCX2YAgyoEMeta?.path ?? "/admin/apps/listing/add",
    meta: addJCX2YAgyoEMeta || {},
    alias: addJCX2YAgyoEMeta?.alias || [],
    redirect: addJCX2YAgyoEMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/listing/add.vue").then(m => m.default || m)
  },
  {
    name: indexrHPkv19k52Meta?.name ?? "admin-apps-listing",
    path: indexrHPkv19k52Meta?.path ?? "/admin/apps/listing",
    meta: indexrHPkv19k52Meta || {},
    alias: indexrHPkv19k52Meta?.alias || [],
    redirect: indexrHPkv19k52Meta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/listing/index.vue").then(m => m.default || m)
  },
  {
    name: mailboxCbBiG8SoKdMeta?.name ?? "admin-apps-mailbox",
    path: mailboxCbBiG8SoKdMeta?.path ?? "/admin/apps/mailbox",
    meta: mailboxCbBiG8SoKdMeta || {},
    alias: mailboxCbBiG8SoKdMeta?.alias || [],
    redirect: mailboxCbBiG8SoKdMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/mailbox.vue").then(m => m.default || m)
  },
  {
    name: marketing0t9B7qW7GLMeta?.name ?? "admin-apps-marketing",
    path: marketing0t9B7qW7GLMeta?.path ?? "/admin/apps/marketing",
    meta: marketing0t9B7qW7GLMeta || {},
    alias: marketing0t9B7qW7GLMeta?.alias || [],
    redirect: marketing0t9B7qW7GLMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/marketing.vue").then(m => m.default || m)
  },
  {
    name: notesuJce14B4jdMeta?.name ?? "admin-apps-notes",
    path: notesuJce14B4jdMeta?.path ?? "/admin/apps/notes",
    meta: notesuJce14B4jdMeta || {},
    alias: notesuJce14B4jdMeta?.alias || [],
    redirect: notesuJce14B4jdMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/notes.vue").then(m => m.default || m)
  },
  {
    name: opportunitiesgzfaHtfqiOMeta?.name ?? "admin-apps-opportunities",
    path: opportunitiesgzfaHtfqiOMeta?.path ?? "/admin/apps/opportunities",
    meta: opportunitiesgzfaHtfqiOMeta || {},
    alias: opportunitiesgzfaHtfqiOMeta?.alias || [],
    redirect: opportunitiesgzfaHtfqiOMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/opportunities.vue").then(m => m.default || m)
  },
  {
    name: scrumboardlI93o3ReP4Meta?.name ?? "admin-apps-scrumboard",
    path: scrumboardlI93o3ReP4Meta?.path ?? "/admin/apps/scrumboard",
    meta: scrumboardlI93o3ReP4Meta || {},
    alias: scrumboardlI93o3ReP4Meta?.alias || [],
    redirect: scrumboardlI93o3ReP4Meta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/scrumboard.vue").then(m => m.default || m)
  },
  {
    name: smartactionpev8xOLj8qMeta?.name ?? "admin-apps-smartaction",
    path: smartactionpev8xOLj8qMeta?.path ?? "/admin/apps/smartaction",
    meta: smartactionpev8xOLj8qMeta || {},
    alias: smartactionpev8xOLj8qMeta?.alias || [],
    redirect: smartactionpev8xOLj8qMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/smartaction.vue").then(m => m.default || m)
  },
  {
    name: todolistE1QUUnGwOYMeta?.name ?? "admin-apps-todolist",
    path: todolistE1QUUnGwOYMeta?.path ?? "/admin/apps/todolist",
    meta: todolistE1QUUnGwOYMeta || {},
    alias: todolistE1QUUnGwOYMeta?.alias || [],
    redirect: todolistE1QUUnGwOYMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/apps/todolist.vue").then(m => m.default || m)
  },
  {
    name: indexi8nCATZISJMeta?.name ?? "admin",
    path: indexi8nCATZISJMeta?.path ?? "/admin",
    meta: indexi8nCATZISJMeta || {},
    alias: indexi8nCATZISJMeta?.alias || [],
    redirect: indexi8nCATZISJMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: profile0GQR50wn91Meta?.name ?? "admin-users-profile",
    path: profile0GQR50wn91Meta?.path ?? "/admin/users/profile",
    meta: profile0GQR50wn91Meta || {},
    alias: profile0GQR50wn91Meta?.alias || [],
    redirect: profile0GQR50wn91Meta?.redirect || undefined,
    component: () => import("/app/pages/admin/users/profile.vue").then(m => m.default || m)
  },
  {
    name: user_45account_45settingsF0Fn7eYxJgMeta?.name ?? "admin-users-user-account-settings",
    path: user_45account_45settingsF0Fn7eYxJgMeta?.path ?? "/admin/users/user-account-settings",
    meta: user_45account_45settingsF0Fn7eYxJgMeta || {},
    alias: user_45account_45settingsF0Fn7eYxJgMeta?.alias || [],
    redirect: user_45account_45settingsF0Fn7eYxJgMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/users/user-account-settings.vue").then(m => m.default || m)
  },
  {
    name: analyticsawZ4iWXl3HMeta?.name ?? "analytics",
    path: analyticsawZ4iWXl3HMeta?.path ?? "/analytics",
    meta: analyticsawZ4iWXl3HMeta || {},
    alias: analyticsawZ4iWXl3HMeta?.alias || [],
    redirect: analyticsawZ4iWXl3HMeta?.redirect || undefined,
    component: () => import("/app/pages/analytics.vue").then(m => m.default || m)
  },
  {
    name: boxed_45lockscreen9PuutlCpOVMeta?.name ?? "auth-boxed-lockscreen",
    path: boxed_45lockscreen9PuutlCpOVMeta?.path ?? "/auth/boxed-lockscreen",
    meta: boxed_45lockscreen9PuutlCpOVMeta || {},
    alias: boxed_45lockscreen9PuutlCpOVMeta?.alias || [],
    redirect: boxed_45lockscreen9PuutlCpOVMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/boxed-lockscreen.vue").then(m => m.default || m)
  },
  {
    name: boxed_45password_45reset47WA1dDqs6Meta?.name ?? "auth-boxed-password-reset",
    path: boxed_45password_45reset47WA1dDqs6Meta?.path ?? "/auth/boxed-password-reset",
    meta: boxed_45password_45reset47WA1dDqs6Meta || {},
    alias: boxed_45password_45reset47WA1dDqs6Meta?.alias || [],
    redirect: boxed_45password_45reset47WA1dDqs6Meta?.redirect || undefined,
    component: () => import("/app/pages/auth/boxed-password-reset.vue").then(m => m.default || m)
  },
  {
    name: boxed_45signinPQdz4KevkGMeta?.name ?? "auth-boxed-signin",
    path: boxed_45signinPQdz4KevkGMeta?.path ?? "/auth/boxed-signin",
    meta: boxed_45signinPQdz4KevkGMeta || {},
    alias: boxed_45signinPQdz4KevkGMeta?.alias || [],
    redirect: boxed_45signinPQdz4KevkGMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/boxed-signin.vue").then(m => m.default || m)
  },
  {
    name: boxed_45signup6O9TBPg4tGMeta?.name ?? "auth-boxed-signup",
    path: boxed_45signup6O9TBPg4tGMeta?.path ?? "/auth/boxed-signup",
    meta: boxed_45signup6O9TBPg4tGMeta || {},
    alias: boxed_45signup6O9TBPg4tGMeta?.alias || [],
    redirect: boxed_45signup6O9TBPg4tGMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/boxed-signup.vue").then(m => m.default || m)
  },
  {
    name: cover_45lockscreenYk2MgkwpFmMeta?.name ?? "auth-cover-lockscreen",
    path: cover_45lockscreenYk2MgkwpFmMeta?.path ?? "/auth/cover-lockscreen",
    meta: cover_45lockscreenYk2MgkwpFmMeta || {},
    alias: cover_45lockscreenYk2MgkwpFmMeta?.alias || [],
    redirect: cover_45lockscreenYk2MgkwpFmMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/cover-lockscreen.vue").then(m => m.default || m)
  },
  {
    name: cover_45loginuZyWJQcrBoMeta?.name ?? "auth-cover-login",
    path: cover_45loginuZyWJQcrBoMeta?.path ?? "/auth/cover-login",
    meta: cover_45loginuZyWJQcrBoMeta || {},
    alias: cover_45loginuZyWJQcrBoMeta?.alias || [],
    redirect: cover_45loginuZyWJQcrBoMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/cover-login.vue").then(m => m.default || m)
  },
  {
    name: cover_45password_45reset0Dp209zw2BMeta?.name ?? "auth-cover-password-reset",
    path: cover_45password_45reset0Dp209zw2BMeta?.path ?? "/auth/cover-password-reset",
    meta: cover_45password_45reset0Dp209zw2BMeta || {},
    alias: cover_45password_45reset0Dp209zw2BMeta?.alias || [],
    redirect: cover_45password_45reset0Dp209zw2BMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/cover-password-reset.vue").then(m => m.default || m)
  },
  {
    name: cover_45registervmnxlWtp1cMeta?.name ?? "auth-cover-register",
    path: cover_45registervmnxlWtp1cMeta?.path ?? "/auth/cover-register",
    meta: cover_45registervmnxlWtp1cMeta || {},
    alias: cover_45registervmnxlWtp1cMeta?.alias || [],
    redirect: cover_45registervmnxlWtp1cMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/cover-register.vue").then(m => m.default || m)
  },
  {
    name: try_45demouZyExdwi4bMeta?.name ?? "auth-try-demo",
    path: try_45demouZyExdwi4bMeta?.path ?? "/auth/try-demo",
    meta: try_45demouZyExdwi4bMeta || {},
    alias: try_45demouZyExdwi4bMeta?.alias || [],
    redirect: try_45demouZyExdwi4bMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/try-demo.vue").then(m => m.default || m)
  },
  {
    name: indexZylsoS0OD7Meta?.name ?? "b2c",
    path: indexZylsoS0OD7Meta?.path ?? "/b2c",
    meta: indexZylsoS0OD7Meta || {},
    alias: indexZylsoS0OD7Meta?.alias || [],
    redirect: indexZylsoS0OD7Meta?.redirect || undefined,
    component: () => import("/app/pages/b2c/index.vue").then(m => m.default || m)
  },
  {
    name: marketingnqQ7kgu8hvMeta?.name ?? "b2c-marketing",
    path: marketingnqQ7kgu8hvMeta?.path ?? "/b2c/marketing",
    meta: marketingnqQ7kgu8hvMeta || {},
    alias: marketingnqQ7kgu8hvMeta?.alias || [],
    redirect: marketingnqQ7kgu8hvMeta?.redirect || undefined,
    component: () => import("/app/pages/b2c/marketing.vue").then(m => m.default || m)
  },
  {
    name: chartsmXog1AvpIqMeta?.name ?? "charts",
    path: chartsmXog1AvpIqMeta?.path ?? "/charts",
    meta: chartsmXog1AvpIqMeta || {},
    alias: chartsmXog1AvpIqMeta?.alias || [],
    redirect: chartsmXog1AvpIqMeta?.redirect || undefined,
    component: () => import("/app/pages/charts.vue").then(m => m.default || m)
  },
  {
    name: accordionsUwvwTzg8igMeta?.name ?? "components-accordions",
    path: accordionsUwvwTzg8igMeta?.path ?? "/components/accordions",
    meta: accordionsUwvwTzg8igMeta || {},
    alias: accordionsUwvwTzg8igMeta?.alias || [],
    redirect: accordionsUwvwTzg8igMeta?.redirect || undefined,
    component: () => import("/app/pages/components/accordions.vue").then(m => m.default || m)
  },
  {
    name: cardsPjbJoa7ViHMeta?.name ?? "components-cards",
    path: cardsPjbJoa7ViHMeta?.path ?? "/components/cards",
    meta: cardsPjbJoa7ViHMeta || {},
    alias: cardsPjbJoa7ViHMeta?.alias || [],
    redirect: cardsPjbJoa7ViHMeta?.redirect || undefined,
    component: () => import("/app/pages/components/cards.vue").then(m => m.default || m)
  },
  {
    name: carouselaGFIr3YbFiMeta?.name ?? "components-carousel",
    path: carouselaGFIr3YbFiMeta?.path ?? "/components/carousel",
    meta: carouselaGFIr3YbFiMeta || {},
    alias: carouselaGFIr3YbFiMeta?.alias || [],
    redirect: carouselaGFIr3YbFiMeta?.redirect || undefined,
    component: () => import("/app/pages/components/carousel.vue").then(m => m.default || m)
  },
  {
    name: countdownlM1qZBRClqMeta?.name ?? "components-countdown",
    path: countdownlM1qZBRClqMeta?.path ?? "/components/countdown",
    meta: countdownlM1qZBRClqMeta || {},
    alias: countdownlM1qZBRClqMeta?.alias || [],
    redirect: countdownlM1qZBRClqMeta?.redirect || undefined,
    component: () => import("/app/pages/components/countdown.vue").then(m => m.default || m)
  },
  {
    name: counterhV3CROPmoPMeta?.name ?? "components-counter",
    path: counterhV3CROPmoPMeta?.path ?? "/components/counter",
    meta: counterhV3CROPmoPMeta || {},
    alias: counterhV3CROPmoPMeta?.alias || [],
    redirect: counterhV3CROPmoPMeta?.redirect || undefined,
    component: () => import("/app/pages/components/counter.vue").then(m => m.default || m)
  },
  {
    name: lightboxzYXPqqPhLRMeta?.name ?? "components-lightbox",
    path: lightboxzYXPqqPhLRMeta?.path ?? "/components/lightbox",
    meta: lightboxzYXPqqPhLRMeta || {},
    alias: lightboxzYXPqqPhLRMeta?.alias || [],
    redirect: lightboxzYXPqqPhLRMeta?.redirect || undefined,
    component: () => import("/app/pages/components/lightbox.vue").then(m => m.default || m)
  },
  {
    name: list_45groupzPlFcWhgP8Meta?.name ?? "components-list-group",
    path: list_45groupzPlFcWhgP8Meta?.path ?? "/components/list-group",
    meta: list_45groupzPlFcWhgP8Meta || {},
    alias: list_45groupzPlFcWhgP8Meta?.alias || [],
    redirect: list_45groupzPlFcWhgP8Meta?.redirect || undefined,
    component: () => import("/app/pages/components/list-group.vue").then(m => m.default || m)
  },
  {
    name: media_45objectLlNVOsVPpEMeta?.name ?? "components-media-object",
    path: media_45objectLlNVOsVPpEMeta?.path ?? "/components/media-object",
    meta: media_45objectLlNVOsVPpEMeta || {},
    alias: media_45objectLlNVOsVPpEMeta?.alias || [],
    redirect: media_45objectLlNVOsVPpEMeta?.redirect || undefined,
    component: () => import("/app/pages/components/media-object.vue").then(m => m.default || m)
  },
  {
    name: modalsvcZKjEcmSIMeta?.name ?? "components-modals",
    path: modalsvcZKjEcmSIMeta?.path ?? "/components/modals",
    meta: modalsvcZKjEcmSIMeta || {},
    alias: modalsvcZKjEcmSIMeta?.alias || [],
    redirect: modalsvcZKjEcmSIMeta?.redirect || undefined,
    component: () => import("/app/pages/components/modals.vue").then(m => m.default || m)
  },
  {
    name: notificationsmlJEGUOXcIMeta?.name ?? "components-notifications",
    path: notificationsmlJEGUOXcIMeta?.path ?? "/components/notifications",
    meta: notificationsmlJEGUOXcIMeta || {},
    alias: notificationsmlJEGUOXcIMeta?.alias || [],
    redirect: notificationsmlJEGUOXcIMeta?.redirect || undefined,
    component: () => import("/app/pages/components/notifications.vue").then(m => m.default || m)
  },
  {
    name: pricing_45tablew9AhkmytE4Meta?.name ?? "components-pricing-table",
    path: pricing_45tablew9AhkmytE4Meta?.path ?? "/components/pricing-table",
    meta: pricing_45tablew9AhkmytE4Meta || {},
    alias: pricing_45tablew9AhkmytE4Meta?.alias || [],
    redirect: pricing_45tablew9AhkmytE4Meta?.redirect || undefined,
    component: () => import("/app/pages/components/pricing-table.vue").then(m => m.default || m)
  },
  {
    name: sweetalert0d0XYlgpbCMeta?.name ?? "components-sweetalert",
    path: sweetalert0d0XYlgpbCMeta?.path ?? "/components/sweetalert",
    meta: sweetalert0d0XYlgpbCMeta || {},
    alias: sweetalert0d0XYlgpbCMeta?.alias || [],
    redirect: sweetalert0d0XYlgpbCMeta?.redirect || undefined,
    component: () => import("/app/pages/components/sweetalert.vue").then(m => m.default || m)
  },
  {
    name: tabsxnPxR4FkY3Meta?.name ?? "components-tabs",
    path: tabsxnPxR4FkY3Meta?.path ?? "/components/tabs",
    meta: tabsxnPxR4FkY3Meta || {},
    alias: tabsxnPxR4FkY3Meta?.alias || [],
    redirect: tabsxnPxR4FkY3Meta?.redirect || undefined,
    component: () => import("/app/pages/components/tabs.vue").then(m => m.default || m)
  },
  {
    name: timeline6adsDXNVPUMeta?.name ?? "components-timeline",
    path: timeline6adsDXNVPUMeta?.path ?? "/components/timeline",
    meta: timeline6adsDXNVPUMeta || {},
    alias: timeline6adsDXNVPUMeta?.alias || [],
    redirect: timeline6adsDXNVPUMeta?.redirect || undefined,
    component: () => import("/app/pages/components/timeline.vue").then(m => m.default || m)
  },
  {
    name: crypto3wfXLz1OUkMeta?.name ?? "crypto",
    path: crypto3wfXLz1OUkMeta?.path ?? "/crypto",
    meta: crypto3wfXLz1OUkMeta || {},
    alias: crypto3wfXLz1OUkMeta?.alias || [],
    redirect: crypto3wfXLz1OUkMeta?.redirect || undefined,
    component: () => import("/app/pages/crypto.vue").then(m => m.default || m)
  },
  {
    name: advancedn4tKgGV5IhMeta?.name ?? "datatables-advanced",
    path: advancedn4tKgGV5IhMeta?.path ?? "/datatables/advanced",
    meta: advancedn4tKgGV5IhMeta || {},
    alias: advancedn4tKgGV5IhMeta?.alias || [],
    redirect: advancedn4tKgGV5IhMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/advanced.vue").then(m => m.default || m)
  },
  {
    name: alt_45paginationChZ9tlCMFJMeta?.name ?? "datatables-alt-pagination",
    path: alt_45paginationChZ9tlCMFJMeta?.path ?? "/datatables/alt-pagination",
    meta: alt_45paginationChZ9tlCMFJMeta || {},
    alias: alt_45paginationChZ9tlCMFJMeta?.alias || [],
    redirect: alt_45paginationChZ9tlCMFJMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/alt-pagination.vue").then(m => m.default || m)
  },
  {
    name: basicAcR9PDUtBPMeta?.name ?? "datatables-basic",
    path: basicAcR9PDUtBPMeta?.path ?? "/datatables/basic",
    meta: basicAcR9PDUtBPMeta || {},
    alias: basicAcR9PDUtBPMeta?.alias || [],
    redirect: basicAcR9PDUtBPMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/basic.vue").then(m => m.default || m)
  },
  {
    name: checkboxIMYFCQ1X0QMeta?.name ?? "datatables-checkbox",
    path: checkboxIMYFCQ1X0QMeta?.path ?? "/datatables/checkbox",
    meta: checkboxIMYFCQ1X0QMeta || {},
    alias: checkboxIMYFCQ1X0QMeta?.alias || [],
    redirect: checkboxIMYFCQ1X0QMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/checkbox.vue").then(m => m.default || m)
  },
  {
    name: clone_45headerOoQyiYCewtMeta?.name ?? "datatables-clone-header",
    path: clone_45headerOoQyiYCewtMeta?.path ?? "/datatables/clone-header",
    meta: clone_45headerOoQyiYCewtMeta || {},
    alias: clone_45headerOoQyiYCewtMeta?.alias || [],
    redirect: clone_45headerOoQyiYCewtMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/clone-header.vue").then(m => m.default || m)
  },
  {
    name: column_45chooserM4a7xaUmrFMeta?.name ?? "datatables-column-chooser",
    path: column_45chooserM4a7xaUmrFMeta?.path ?? "/datatables/column-chooser",
    meta: column_45chooserM4a7xaUmrFMeta || {},
    alias: column_45chooserM4a7xaUmrFMeta?.alias || [],
    redirect: column_45chooserM4a7xaUmrFMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/column-chooser.vue").then(m => m.default || m)
  },
  {
    name: columns_45filter3pnQpBq1PNMeta?.name ?? "datatables-columns-filter",
    path: columns_45filter3pnQpBq1PNMeta?.path ?? "/datatables/columns-filter",
    meta: columns_45filter3pnQpBq1PNMeta || {},
    alias: columns_45filter3pnQpBq1PNMeta?.alias || [],
    redirect: columns_45filter3pnQpBq1PNMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/columns-filter.vue").then(m => m.default || m)
  },
  {
    name: exporteznYh68XblMeta?.name ?? "datatables-export",
    path: exporteznYh68XblMeta?.path ?? "/datatables/export",
    meta: exporteznYh68XblMeta || {},
    alias: exporteznYh68XblMeta?.alias || [],
    redirect: exporteznYh68XblMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/export.vue").then(m => m.default || m)
  },
  {
    name: multi_45column47lVAQAb3oMeta?.name ?? "datatables-multi-column",
    path: multi_45column47lVAQAb3oMeta?.path ?? "/datatables/multi-column",
    meta: multi_45column47lVAQAb3oMeta || {},
    alias: multi_45column47lVAQAb3oMeta?.alias || [],
    redirect: multi_45column47lVAQAb3oMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/multi-column.vue").then(m => m.default || m)
  },
  {
    name: multiple_45tablesqL0WjDoP4bMeta?.name ?? "datatables-multiple-tables",
    path: multiple_45tablesqL0WjDoP4bMeta?.path ?? "/datatables/multiple-tables",
    meta: multiple_45tablesqL0WjDoP4bMeta || {},
    alias: multiple_45tablesqL0WjDoP4bMeta?.alias || [],
    redirect: multiple_45tablesqL0WjDoP4bMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/multiple-tables.vue").then(m => m.default || m)
  },
  {
    name: order_45sortingEPRDOqKsgwMeta?.name ?? "datatables-order-sorting",
    path: order_45sortingEPRDOqKsgwMeta?.path ?? "/datatables/order-sorting",
    meta: order_45sortingEPRDOqKsgwMeta || {},
    alias: order_45sortingEPRDOqKsgwMeta?.alias || [],
    redirect: order_45sortingEPRDOqKsgwMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/order-sorting.vue").then(m => m.default || m)
  },
  {
    name: range_45searchxRIL6qkmVBMeta?.name ?? "datatables-range-search",
    path: range_45searchxRIL6qkmVBMeta?.path ?? "/datatables/range-search",
    meta: range_45searchxRIL6qkmVBMeta || {},
    alias: range_45searchxRIL6qkmVBMeta?.alias || [],
    redirect: range_45searchxRIL6qkmVBMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/range-search.vue").then(m => m.default || m)
  },
  {
    name: skinfZD3H3UBGJMeta?.name ?? "datatables-skin",
    path: skinfZD3H3UBGJMeta?.path ?? "/datatables/skin",
    meta: skinfZD3H3UBGJMeta || {},
    alias: skinfZD3H3UBGJMeta?.alias || [],
    redirect: skinfZD3H3UBGJMeta?.redirect || undefined,
    component: () => import("/app/pages/datatables/skin.vue").then(m => m.default || m)
  },
  {
    name: sticky_45headerNx7TRt8f24Meta?.name ?? "datatables-sticky-header",
    path: sticky_45headerNx7TRt8f24Meta?.path ?? "/datatables/sticky-header",
    meta: sticky_45headerNx7TRt8f24Meta || {},
    alias: sticky_45headerNx7TRt8f24Meta?.alias || [],
    redirect: sticky_45headerNx7TRt8f24Meta?.redirect || undefined,
    component: () => import("/app/pages/datatables/sticky-header.vue").then(m => m.default || m)
  },
  {
    name: dragndropQCs8cVnwWuMeta?.name ?? "dragndrop",
    path: dragndropQCs8cVnwWuMeta?.path ?? "/dragndrop",
    meta: dragndropQCs8cVnwWuMeta || {},
    alias: dragndropQCs8cVnwWuMeta?.alias || [],
    redirect: dragndropQCs8cVnwWuMeta?.redirect || undefined,
    component: () => import("/app/pages/dragndrop.vue").then(m => m.default || m)
  },
  {
    name: alerts7wwYLn17IUMeta?.name ?? "elements-alerts",
    path: alerts7wwYLn17IUMeta?.path ?? "/elements/alerts",
    meta: alerts7wwYLn17IUMeta || {},
    alias: alerts7wwYLn17IUMeta?.alias || [],
    redirect: alerts7wwYLn17IUMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/alerts.vue").then(m => m.default || m)
  },
  {
    name: avatarA2Pd91LZRYMeta?.name ?? "elements-avatar",
    path: avatarA2Pd91LZRYMeta?.path ?? "/elements/avatar",
    meta: avatarA2Pd91LZRYMeta || {},
    alias: avatarA2Pd91LZRYMeta?.alias || [],
    redirect: avatarA2Pd91LZRYMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/avatar.vue").then(m => m.default || m)
  },
  {
    name: badgesLzwDfXJnRzMeta?.name ?? "elements-badges",
    path: badgesLzwDfXJnRzMeta?.path ?? "/elements/badges",
    meta: badgesLzwDfXJnRzMeta || {},
    alias: badgesLzwDfXJnRzMeta?.alias || [],
    redirect: badgesLzwDfXJnRzMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/badges.vue").then(m => m.default || m)
  },
  {
    name: breadcrumbs0MWvM7qnSYMeta?.name ?? "elements-breadcrumbs",
    path: breadcrumbs0MWvM7qnSYMeta?.path ?? "/elements/breadcrumbs",
    meta: breadcrumbs0MWvM7qnSYMeta || {},
    alias: breadcrumbs0MWvM7qnSYMeta?.alias || [],
    redirect: breadcrumbs0MWvM7qnSYMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/breadcrumbs.vue").then(m => m.default || m)
  },
  {
    name: buttons_45groupIyDmfkCUs8Meta?.name ?? "elements-buttons-group",
    path: buttons_45groupIyDmfkCUs8Meta?.path ?? "/elements/buttons-group",
    meta: buttons_45groupIyDmfkCUs8Meta || {},
    alias: buttons_45groupIyDmfkCUs8Meta?.alias || [],
    redirect: buttons_45groupIyDmfkCUs8Meta?.redirect || undefined,
    component: () => import("/app/pages/elements/buttons-group.vue").then(m => m.default || m)
  },
  {
    name: buttonsKbjhiBShY1Meta?.name ?? "elements-buttons",
    path: buttonsKbjhiBShY1Meta?.path ?? "/elements/buttons",
    meta: buttonsKbjhiBShY1Meta || {},
    alias: buttonsKbjhiBShY1Meta?.alias || [],
    redirect: buttonsKbjhiBShY1Meta?.redirect || undefined,
    component: () => import("/app/pages/elements/buttons.vue").then(m => m.default || m)
  },
  {
    name: color_45libraryxNIfy6dCEcMeta?.name ?? "elements-color-library",
    path: color_45libraryxNIfy6dCEcMeta?.path ?? "/elements/color-library",
    meta: color_45libraryxNIfy6dCEcMeta || {},
    alias: color_45libraryxNIfy6dCEcMeta?.alias || [],
    redirect: color_45libraryxNIfy6dCEcMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/color-library.vue").then(m => m.default || m)
  },
  {
    name: dropdownfgz6OL21RPMeta?.name ?? "elements-dropdown",
    path: dropdownfgz6OL21RPMeta?.path ?? "/elements/dropdown",
    meta: dropdownfgz6OL21RPMeta || {},
    alias: dropdownfgz6OL21RPMeta?.alias || [],
    redirect: dropdownfgz6OL21RPMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/dropdown.vue").then(m => m.default || m)
  },
  {
    name: infobox6LfI5v5AjpMeta?.name ?? "elements-infobox",
    path: infobox6LfI5v5AjpMeta?.path ?? "/elements/infobox",
    meta: infobox6LfI5v5AjpMeta || {},
    alias: infobox6LfI5v5AjpMeta?.alias || [],
    redirect: infobox6LfI5v5AjpMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/infobox.vue").then(m => m.default || m)
  },
  {
    name: jumbotronbQAftCp2hwMeta?.name ?? "elements-jumbotron",
    path: jumbotronbQAftCp2hwMeta?.path ?? "/elements/jumbotron",
    meta: jumbotronbQAftCp2hwMeta || {},
    alias: jumbotronbQAftCp2hwMeta?.alias || [],
    redirect: jumbotronbQAftCp2hwMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/jumbotron.vue").then(m => m.default || m)
  },
  {
    name: loaderXrkieGQGJBMeta?.name ?? "elements-loader",
    path: loaderXrkieGQGJBMeta?.path ?? "/elements/loader",
    meta: loaderXrkieGQGJBMeta || {},
    alias: loaderXrkieGQGJBMeta?.alias || [],
    redirect: loaderXrkieGQGJBMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/loader.vue").then(m => m.default || m)
  },
  {
    name: paginationXlxxYI4iKNMeta?.name ?? "elements-pagination",
    path: paginationXlxxYI4iKNMeta?.path ?? "/elements/pagination",
    meta: paginationXlxxYI4iKNMeta || {},
    alias: paginationXlxxYI4iKNMeta?.alias || [],
    redirect: paginationXlxxYI4iKNMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/pagination.vue").then(m => m.default || m)
  },
  {
    name: popoversgWqJUdpnWoMeta?.name ?? "elements-popovers",
    path: popoversgWqJUdpnWoMeta?.path ?? "/elements/popovers",
    meta: popoversgWqJUdpnWoMeta || {},
    alias: popoversgWqJUdpnWoMeta?.alias || [],
    redirect: popoversgWqJUdpnWoMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/popovers.vue").then(m => m.default || m)
  },
  {
    name: progress_45barAR6RX16OgyMeta?.name ?? "elements-progress-bar",
    path: progress_45barAR6RX16OgyMeta?.path ?? "/elements/progress-bar",
    meta: progress_45barAR6RX16OgyMeta || {},
    alias: progress_45barAR6RX16OgyMeta?.alias || [],
    redirect: progress_45barAR6RX16OgyMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/progress-bar.vue").then(m => m.default || m)
  },
  {
    name: searchXT3z5qS2y1Meta?.name ?? "elements-search",
    path: searchXT3z5qS2y1Meta?.path ?? "/elements/search",
    meta: searchXT3z5qS2y1Meta || {},
    alias: searchXT3z5qS2y1Meta?.alias || [],
    redirect: searchXT3z5qS2y1Meta?.redirect || undefined,
    component: () => import("/app/pages/elements/search.vue").then(m => m.default || m)
  },
  {
    name: tooltipsfDf31TBwa5Meta?.name ?? "elements-tooltips",
    path: tooltipsfDf31TBwa5Meta?.path ?? "/elements/tooltips",
    meta: tooltipsfDf31TBwa5Meta || {},
    alias: tooltipsfDf31TBwa5Meta?.alias || [],
    redirect: tooltipsfDf31TBwa5Meta?.redirect || undefined,
    component: () => import("/app/pages/elements/tooltips.vue").then(m => m.default || m)
  },
  {
    name: treeviewRw0hin8tbUMeta?.name ?? "elements-treeview",
    path: treeviewRw0hin8tbUMeta?.path ?? "/elements/treeview",
    meta: treeviewRw0hin8tbUMeta || {},
    alias: treeviewRw0hin8tbUMeta?.alias || [],
    redirect: treeviewRw0hin8tbUMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/treeview.vue").then(m => m.default || m)
  },
  {
    name: typographyeHAr3Wa5DEMeta?.name ?? "elements-typography",
    path: typographyeHAr3Wa5DEMeta?.path ?? "/elements/typography",
    meta: typographyeHAr3Wa5DEMeta || {},
    alias: typographyeHAr3Wa5DEMeta?.alias || [],
    redirect: typographyeHAr3Wa5DEMeta?.redirect || undefined,
    component: () => import("/app/pages/elements/typography.vue").then(m => m.default || m)
  },
  {
    name: financeMjIGPbjS9hMeta?.name ?? "finance",
    path: financeMjIGPbjS9hMeta?.path ?? "/finance",
    meta: financeMjIGPbjS9hMeta || {},
    alias: financeMjIGPbjS9hMeta?.alias || [],
    redirect: financeMjIGPbjS9hMeta?.redirect || undefined,
    component: () => import("/app/pages/finance.vue").then(m => m.default || m)
  },
  {
    name: font_45icons30g3Sron1XMeta?.name ?? "font-icons",
    path: font_45icons30g3Sron1XMeta?.path ?? "/font-icons",
    meta: font_45icons30g3Sron1XMeta || {},
    alias: font_45icons30g3Sron1XMeta?.alias || [],
    redirect: font_45icons30g3Sron1XMeta?.redirect || undefined,
    component: () => import("/app/pages/font-icons.vue").then(m => m.default || m)
  },
  {
    name: basic4d0ZhFQRAaMeta?.name ?? "forms-basic",
    path: basic4d0ZhFQRAaMeta?.path ?? "/forms/basic",
    meta: basic4d0ZhFQRAaMeta || {},
    alias: basic4d0ZhFQRAaMeta?.alias || [],
    redirect: basic4d0ZhFQRAaMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/basic.vue").then(m => m.default || m)
  },
  {
    name: checkbox_45radiog0clgjf09hMeta?.name ?? "forms-checkbox-radio",
    path: checkbox_45radiog0clgjf09hMeta?.path ?? "/forms/checkbox-radio",
    meta: checkbox_45radiog0clgjf09hMeta || {},
    alias: checkbox_45radiog0clgjf09hMeta?.alias || [],
    redirect: checkbox_45radiog0clgjf09hMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/checkbox-radio.vue").then(m => m.default || m)
  },
  {
    name: clipboardT5kxFLN3FCMeta?.name ?? "forms-clipboard",
    path: clipboardT5kxFLN3FCMeta?.path ?? "/forms/clipboard",
    meta: clipboardT5kxFLN3FCMeta || {},
    alias: clipboardT5kxFLN3FCMeta?.alias || [],
    redirect: clipboardT5kxFLN3FCMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/clipboard.vue").then(m => m.default || m)
  },
  {
    name: date_45pickerxBpRZUt9KbMeta?.name ?? "forms-date-picker",
    path: date_45pickerxBpRZUt9KbMeta?.path ?? "/forms/date-picker",
    meta: date_45pickerxBpRZUt9KbMeta || {},
    alias: date_45pickerxBpRZUt9KbMeta?.alias || [],
    redirect: date_45pickerxBpRZUt9KbMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/date-picker.vue").then(m => m.default || m)
  },
  {
    name: file_45uploadqdBcW6XYnhMeta?.name ?? "forms-file-upload",
    path: file_45uploadqdBcW6XYnhMeta?.path ?? "/forms/file-upload",
    meta: file_45uploadqdBcW6XYnhMeta || {},
    alias: file_45uploadqdBcW6XYnhMeta?.alias || [],
    redirect: file_45uploadqdBcW6XYnhMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/file-upload.vue").then(m => m.default || m)
  },
  {
    name: input_45groupS5PaG385fyMeta?.name ?? "forms-input-group",
    path: input_45groupS5PaG385fyMeta?.path ?? "/forms/input-group",
    meta: input_45groupS5PaG385fyMeta || {},
    alias: input_45groupS5PaG385fyMeta?.alias || [],
    redirect: input_45groupS5PaG385fyMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/input-group.vue").then(m => m.default || m)
  },
  {
    name: input_45maskJ05OMPC6ZtMeta?.name ?? "forms-input-mask",
    path: input_45maskJ05OMPC6ZtMeta?.path ?? "/forms/input-mask",
    meta: input_45maskJ05OMPC6ZtMeta || {},
    alias: input_45maskJ05OMPC6ZtMeta?.alias || [],
    redirect: input_45maskJ05OMPC6ZtMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/input-mask.vue").then(m => m.default || m)
  },
  {
    name: layoutshU8mEbz15ZMeta?.name ?? "forms-layouts",
    path: layoutshU8mEbz15ZMeta?.path ?? "/forms/layouts",
    meta: layoutshU8mEbz15ZMeta || {},
    alias: layoutshU8mEbz15ZMeta?.alias || [],
    redirect: layoutshU8mEbz15ZMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/layouts.vue").then(m => m.default || m)
  },
  {
    name: markdown_45editorX8EdcpWp7yMeta?.name ?? "forms-markdown-editor",
    path: markdown_45editorX8EdcpWp7yMeta?.path ?? "/forms/markdown-editor",
    meta: markdown_45editorX8EdcpWp7yMeta || {},
    alias: markdown_45editorX8EdcpWp7yMeta?.alias || [],
    redirect: markdown_45editorX8EdcpWp7yMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/markdown-editor.vue").then(m => m.default || m)
  },
  {
    name: quill_45editorMEMRSEcMdTMeta?.name ?? "forms-quill-editor",
    path: quill_45editorMEMRSEcMdTMeta?.path ?? "/forms/quill-editor",
    meta: quill_45editorMEMRSEcMdTMeta || {},
    alias: quill_45editorMEMRSEcMdTMeta?.alias || [],
    redirect: quill_45editorMEMRSEcMdTMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/quill-editor.vue").then(m => m.default || m)
  },
  {
    name: select2Kddnwvxd1NMeta?.name ?? "forms-select2",
    path: select2Kddnwvxd1NMeta?.path ?? "/forms/select2",
    meta: select2Kddnwvxd1NMeta || {},
    alias: select2Kddnwvxd1NMeta?.alias || [],
    redirect: select2Kddnwvxd1NMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/select2.vue").then(m => m.default || m)
  },
  {
    name: switchesQX9jLi1wm0Meta?.name ?? "forms-switches",
    path: switchesQX9jLi1wm0Meta?.path ?? "/forms/switches",
    meta: switchesQX9jLi1wm0Meta || {},
    alias: switchesQX9jLi1wm0Meta?.alias || [],
    redirect: switchesQX9jLi1wm0Meta?.redirect || undefined,
    component: () => import("/app/pages/forms/switches.vue").then(m => m.default || m)
  },
  {
    name: touchspin20lQo5txyJMeta?.name ?? "forms-touchspin",
    path: touchspin20lQo5txyJMeta?.path ?? "/forms/touchspin",
    meta: touchspin20lQo5txyJMeta || {},
    alias: touchspin20lQo5txyJMeta?.alias || [],
    redirect: touchspin20lQo5txyJMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/touchspin.vue").then(m => m.default || m)
  },
  {
    name: validationoK9hoJ34BkMeta?.name ?? "forms-validation",
    path: validationoK9hoJ34BkMeta?.path ?? "/forms/validation",
    meta: validationoK9hoJ34BkMeta || {},
    alias: validationoK9hoJ34BkMeta?.alias || [],
    redirect: validationoK9hoJ34BkMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/validation.vue").then(m => m.default || m)
  },
  {
    name: wizardskeh2RYDhnpMeta?.name ?? "forms-wizards",
    path: wizardskeh2RYDhnpMeta?.path ?? "/forms/wizards",
    meta: wizardskeh2RYDhnpMeta || {},
    alias: wizardskeh2RYDhnpMeta?.alias || [],
    redirect: wizardskeh2RYDhnpMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/wizards.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mailboxNUDjlv6tulMeta?.name ?? "mailbox",
    path: mailboxNUDjlv6tulMeta?.path ?? "/mailbox",
    meta: mailboxNUDjlv6tulMeta || {},
    alias: mailboxNUDjlv6tulMeta?.alias || [],
    redirect: mailboxNUDjlv6tulMeta?.redirect || undefined,
    component: () => import("/app/pages/mailbox.vue").then(m => m.default || m)
  },
  {
    name: network3nefSS6RrdMeta?.name ?? "network",
    path: network3nefSS6RrdMeta?.path ?? "/network",
    meta: network3nefSS6RrdMeta || {},
    alias: network3nefSS6RrdMeta?.alias || [],
    redirect: network3nefSS6RrdMeta?.redirect || undefined,
    component: () => import("/app/pages/network.vue").then(m => m.default || m)
  },
  {
    name: orderBjfxujOe1OMeta?.name ?? "order",
    path: orderBjfxujOe1OMeta?.path ?? "/order",
    meta: orderBjfxujOe1OMeta || {},
    alias: orderBjfxujOe1OMeta?.alias || [],
    redirect: orderBjfxujOe1OMeta?.redirect || undefined,
    component: () => import("/app/pages/order.vue").then(m => m.default || m)
  },
  {
    name: coming_45soon_45boxedIiRVY5RmnYMeta?.name ?? "pages-coming-soon-boxed",
    path: coming_45soon_45boxedIiRVY5RmnYMeta?.path ?? "/pages/coming-soon-boxed",
    meta: coming_45soon_45boxedIiRVY5RmnYMeta || {},
    alias: coming_45soon_45boxedIiRVY5RmnYMeta?.alias || [],
    redirect: coming_45soon_45boxedIiRVY5RmnYMeta?.redirect || undefined,
    component: () => import("/app/pages/pages/coming-soon-boxed.vue").then(m => m.default || m)
  },
  {
    name: coming_45soon_45cover9En00x5dcNMeta?.name ?? "pages-coming-soon-cover",
    path: coming_45soon_45cover9En00x5dcNMeta?.path ?? "/pages/coming-soon-cover",
    meta: coming_45soon_45cover9En00x5dcNMeta || {},
    alias: coming_45soon_45cover9En00x5dcNMeta?.alias || [],
    redirect: coming_45soon_45cover9En00x5dcNMeta?.redirect || undefined,
    component: () => import("/app/pages/pages/coming-soon-cover.vue").then(m => m.default || m)
  },
  {
    name: contact_45us_45boxedCNp7hQWXH5Meta?.name ?? "pages-contact-us-boxed",
    path: contact_45us_45boxedCNp7hQWXH5Meta?.path ?? "/pages/contact-us-boxed",
    meta: contact_45us_45boxedCNp7hQWXH5Meta || {},
    alias: contact_45us_45boxedCNp7hQWXH5Meta?.alias || [],
    redirect: contact_45us_45boxedCNp7hQWXH5Meta?.redirect || undefined,
    component: () => import("/app/pages/pages/contact-us-boxed.vue").then(m => m.default || m)
  },
  {
    name: contact_45us_45coverDqlH83GANbMeta?.name ?? "pages-contact-us-cover",
    path: contact_45us_45coverDqlH83GANbMeta?.path ?? "/pages/contact-us-cover",
    meta: contact_45us_45coverDqlH83GANbMeta || {},
    alias: contact_45us_45coverDqlH83GANbMeta?.alias || [],
    redirect: contact_45us_45coverDqlH83GANbMeta?.redirect || undefined,
    component: () => import("/app/pages/pages/contact-us-cover.vue").then(m => m.default || m)
  },
  {
    name: error404hTN0doY6zqMeta?.name ?? "pages-error404",
    path: error404hTN0doY6zqMeta?.path ?? "/pages/error404",
    meta: error404hTN0doY6zqMeta || {},
    alias: error404hTN0doY6zqMeta?.alias || [],
    redirect: error404hTN0doY6zqMeta?.redirect || undefined,
    component: () => import("/app/pages/pages/error404.vue").then(m => m.default || m)
  },
  {
    name: error500OQ7pwxlt92Meta?.name ?? "pages-error500",
    path: error500OQ7pwxlt92Meta?.path ?? "/pages/error500",
    meta: error500OQ7pwxlt92Meta || {},
    alias: error500OQ7pwxlt92Meta?.alias || [],
    redirect: error500OQ7pwxlt92Meta?.redirect || undefined,
    component: () => import("/app/pages/pages/error500.vue").then(m => m.default || m)
  },
  {
    name: error503mOESQwwiUHMeta?.name ?? "pages-error503",
    path: error503mOESQwwiUHMeta?.path ?? "/pages/error503",
    meta: error503mOESQwwiUHMeta || {},
    alias: error503mOESQwwiUHMeta?.alias || [],
    redirect: error503mOESQwwiUHMeta?.redirect || undefined,
    component: () => import("/app/pages/pages/error503.vue").then(m => m.default || m)
  },
  {
    name: faq9zkrynBMU3Meta?.name ?? "pages-faq",
    path: faq9zkrynBMU3Meta?.path ?? "/pages/faq",
    meta: faq9zkrynBMU3Meta || {},
    alias: faq9zkrynBMU3Meta?.alias || [],
    redirect: faq9zkrynBMU3Meta?.redirect || undefined,
    component: () => import("/app/pages/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: knowledge_45baseZEbA2pHmpEMeta?.name ?? "pages-knowledge-base",
    path: knowledge_45baseZEbA2pHmpEMeta?.path ?? "/pages/knowledge-base",
    meta: knowledge_45baseZEbA2pHmpEMeta || {},
    alias: knowledge_45baseZEbA2pHmpEMeta?.alias || [],
    redirect: knowledge_45baseZEbA2pHmpEMeta?.redirect || undefined,
    component: () => import("/app/pages/pages/knowledge-base.vue").then(m => m.default || m)
  },
  {
    name: maintenenceZfksFlNFGfMeta?.name ?? "pages-maintenence",
    path: maintenenceZfksFlNFGfMeta?.path ?? "/pages/maintenence",
    meta: maintenenceZfksFlNFGfMeta || {},
    alias: maintenenceZfksFlNFGfMeta?.alias || [],
    redirect: maintenenceZfksFlNFGfMeta?.redirect || undefined,
    component: () => import("/app/pages/pages/maintenence.vue").then(m => m.default || m)
  },
  {
    name: success0bZk2aWKHZMeta?.name ?? "success",
    path: success0bZk2aWKHZMeta?.path ?? "/success",
    meta: success0bZk2aWKHZMeta || {},
    alias: success0bZk2aWKHZMeta?.alias || [],
    redirect: success0bZk2aWKHZMeta?.redirect || undefined,
    component: () => import("/app/pages/success.vue").then(m => m.default || m)
  },
  {
    name: tablesObwGmyK6sPMeta?.name ?? "tables",
    path: tablesObwGmyK6sPMeta?.path ?? "/tables",
    meta: tablesObwGmyK6sPMeta || {},
    alias: tablesObwGmyK6sPMeta?.alias || [],
    redirect: tablesObwGmyK6sPMeta?.redirect || undefined,
    component: () => import("/app/pages/tables.vue").then(m => m.default || m)
  },
  {
    name: trial_45modevh6rBg3TbfMeta?.name ?? "trial-mode",
    path: trial_45modevh6rBg3TbfMeta?.path ?? "/trial-mode",
    meta: trial_45modevh6rBg3TbfMeta || {},
    alias: trial_45modevh6rBg3TbfMeta?.alias || [],
    redirect: trial_45modevh6rBg3TbfMeta?.redirect || undefined,
    component: () => import("/app/pages/trial-mode.vue").then(m => m.default || m)
  },
  {
    name: widgets3jvG0ysNM4Meta?.name ?? "widgets",
    path: widgets3jvG0ysNM4Meta?.path ?? "/widgets",
    meta: widgets3jvG0ysNM4Meta || {},
    alias: widgets3jvG0ysNM4Meta?.alias || [],
    redirect: widgets3jvG0ysNM4Meta?.redirect || undefined,
    component: () => import("/app/pages/widgets.vue").then(m => m.default || m)
  }
]